import { TenantFinder } from "./TenantFinder";
import { CTA } from "./components/CTA";
import AppIcon from "./assets/app-logo.svg";
import PoweredByShareGateLogo from "./assets/powered-by.svg";

function App() {
  return (
    <div className="flex flex-column items-center pv7 ph6 ph13-ns pv13-ns min-vh-100 w-100 overflow-hidden">
      <a className="self-start-ns" href="https://sharegate.com/">
        <img src={PoweredByShareGateLogo} alt="ShareGate"/>
      </a>
      <div className="flex flex-column items-center justify-center flex-grow-1 w-100">
            <a href="/">
              <img src={AppIcon} alt="What is my tenant Id" className="w-90px w-125px-ns mv4" />
            </a>
            <TenantFinder />
            <CTA />
      </div>
  </div>
  );
}

export default App;
