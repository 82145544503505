import Logo from '../assets/logo.svg'
import Circle from '../assets/circle.svg'
import StateOfMS from '../assets/state-of-ms.svg'
import { ButtonAsLink } from "@sharegate/orbit-ui";

export const CTA = ( ) => {

    return <div className="w-100 mw-720px-ns mt5 sg-cta pa7 flex-ns mt12 items-center tc tl-ns br4 overflow-hidden relative">
        <div className="w-two-thirds-ns pr10-ns">
            <img className="circle-1 absolute top-0" src={Circle} alt="State of Microsoft 365" />
            <img src={Logo} alt="ShareGate" />
            <h3>Stay up-to-date on the latest migration, modernization, and security trends!</h3>
            {/* @ts-ignore */}
            <ButtonAsLink className="center ml0-ns" href="https://sharegate.com/resource/microsoft365-cloud-trends-white-paper-2021" target="_blank" variant="primary" size="sm" backgroundColor="#0E1C3D" backgroundColorHover="#2b3a5e" backgroundColorActive="#2b3a5e">
                Get the free report
            </ButtonAsLink>
            <img className="circle-2 absolute w8 bottom-0" src={Circle} alt="State of Microsoft 365" />
        </div>
        <div className="dn db-ns w-third">
            <img src={StateOfMS} alt="State of Microsoft 365" />
        </div>
    </div>
}