import { Label, Button, CrossButton, TextInput, ErrorMessage, Field, Form } from "@sharegate/orbit-ui";
import { CopyToClipboardButton } from "./components";
import { useState, FormEvent } from "react";

enum TenantFinderState {
    Waiting,
    Fetching,
    Found,
    NotFound
}

interface TenantSearchProps {
    componentState: TenantFinderState;
    queryValue: string;
    setComponentState: (value: TenantFinderState) => void;
    setTenantId: (value: string) => void;
    setQueryValue: (value: string) => void;
}

const TenantSearch = ({componentState, queryValue, setComponentState, setTenantId, setQueryValue}: TenantSearchProps) => {
    const handleOnSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setComponentState(TenantFinderState.Fetching);
        try {
            const response = await fetch(`https://login.microsoftonline.com/${queryValue}/.well-known/openid-configuration`);
            const data = await response.json();
    
            const endPoint = data.token_endpoint;
            const id = endPoint.split("/")[3];
            setComponentState(TenantFinderState.Found);
            setTenantId(id);
        }
        catch {
            setComponentState(TenantFinderState.NotFound);
        }
    };

    const handleOnClear = () => {
        setQueryValue("");
        setComponentState(TenantFinderState.Waiting);
    }

    const submitButtonText = componentState === TenantFinderState.Found ? "Tenant found!" : "Find my tenant ID";

    return <>
        <h1 className="f1-ns lh3-ns f4 lh4 fw6 white mt0 mb9 mb5-ns tc">What is your Microsoft Azure and Microsoft 365 tenant ID?</h1>
        <div>
            <Form fluid onSubmit={handleOnSubmit}>
                <Field validationState={componentState !== TenantFinderState.NotFound? "valid": "invalid"}>
                    <Label className="f5-ns lh2-ns f7 lh3 fw5 white mt0 mb1">Enter your domain name</Label>
                    <TextInput 
                        autoFocus 
                        fluid 
                        button={<CrossButton onClick={handleOnClear} aria-label="Clear value" />}
                        disabled={componentState === TenantFinderState.Fetching} 
                        value={queryValue} 
                        placeholder="contoso.com" 
                        onChange={({target}) => setQueryValue(target.value)} 
                    />
                    <ErrorMessage>Couldn’t find your domain, make sure you entered it properly.</ErrorMessage>
                </Field>
                <div className="flex justify-center">
                    <Button
                        variant="primary" 
                        type="submit" 
                        disabled={componentState === TenantFinderState.Fetching || !queryValue}
                    >
                        {submitButtonText}
                    </Button>
                </div>
            </Form>
        </div>
    </>
}

interface TenantResultProps {
    tenantId: string;
    query: string;
}

const TenantResult = ({tenantId, query}: TenantResultProps) => {
    return <>
        <h1 className="f1-ns lh3-ns f4 lh4 fw6 white mt0 mb9 tc">Here is the tenant ID for {query}</h1>
        <div>
            <TextInput fluid readOnly value={tenantId} />
            <div className="flex justify-center mt6">
                <CopyToClipboardButton contentToCopy={tenantId}>
                    Copy to clipboard
                </CopyToClipboardButton>
            </div>
        </div>
    </>
}

export const TenantFinder = () => {
    const [componentState, setComponentState] = useState(TenantFinderState.Waiting);
    const [queryValue, setQueryValue] = useState("");
    const [tenantId, setTenantId] = useState("");

    return <div className="w-100 mw-720px-ns">
        {componentState !== TenantFinderState.Found ? 
            <TenantSearch 
                componentState={componentState}
                queryValue={queryValue}
                setComponentState={setComponentState}
                setQueryValue={setQueryValue}
                setTenantId={setTenantId} 
            /> :
            <TenantResult 
                tenantId={tenantId} 
                query={queryValue} 
            />
        }
    </div>
}